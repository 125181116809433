import Vue from "vue/dist/vue.esm";
import ComponentsLatestBoxes from "./components_latest_boxes";
import ComponentsNanakakeBoxes from "./components_nanakake";

// ui状態記憶のクラス呼び出し
var usageHistory = new chari.UsageHistory();

var portalLatestBoxes = {
  extends: ComponentsLatestBoxes.LatestBoxes,
  props: ["jushouTabs"],
  mounted: function() {
    this.fetchLatestBoxes(this.targetType);
  },
  watch: {
    targetType: function() {
      this.fetchLatestBoxes(this.targetType);
    },
  },
  computed: {
    targetType: function() {
      return chari.util.getKey(chari.const.KEIRIN_BOX_TYPE, this.jushouTabs);
    },
  },
};

var nanakakeBoxes = {
  extends: ComponentsNanakakeBoxes.nanakakeBoxes,
  props: ["gameType"],
};

const vm = new Vue({
  el: "#vue-portal",
  data: {
    nanakakeTabs: 1, // 1: keirin, 2: autorace
    jushouTabs: 4, // dokanto: 1, chariloto: 3, chariloto_select: 4, chariloto_5: 5, chariloto_3: 6
    items: [],
  },
  created: function() {
    this.nanakakeTabs = usageHistory.topNanakakeTab
      ? usageHistory.topNanakakeTab
      : 1;
    this.jushouTabs = usageHistory.topJushouTab ? usageHistory.topJushouTab : 4;
  },
  components: {
    "latest-boxes": portalLatestBoxes,
    "nanakake-boxes": nanakakeBoxes,
  },
  methods: {
    switchNanakake: function(type) {
      this.nanakakeTabs = type;
      usageHistory.topNanakakeTab = type;
    },
    switchJushou: function(type) {
      this.jushouTabs = type;
      usageHistory.topJushouTab = type;
    },
    getJushouLabel: function(type) {
      return Object.keys(chari.const.KEIRIN_BOX_TYPE).find(function(e) {
        return chari.const.KEIRIN_BOX_TYPE[e] === type;
      });
    },
  },
  computed: {
    gameType: function() {
      return this.nanakakeTabs;
    },
  },
});
