import imgLogoChariloto from '../../images/logo/logo_chariloto.svg';
import imgLogoChariloto3 from '../../images/logo/logo_chariloto_3.svg';
import imgLogoChariloto5 from '../../images/logo/logo_chariloto_5.svg';
import imgLogoCharilotoSelect from '../../images/logo/logo_chariloto_select.svg';
import imgLogoDokanto from '../../images/logo/logo_dokanto.svg';
import imgLogoDokanto4two from '../../images/logo/logo_dokanto_4two.svg';

export default {
    methods: {
        logos: function(name) {
            const logoFiles = {
                logoChariloto: imgLogoChariloto,
                logoChariloto3: imgLogoChariloto3,
                logoChariloto5: imgLogoChariloto5,
                logoCharilotoSelect: imgLogoCharilotoSelect,
                logoDokanto: imgLogoDokanto,
                logoDokanto4two: imgLogoDokanto4two
            }
            return logoFiles[name];
        }
    }
}

