import imgIconF1 from "../../images/icon/icon_f1.png";
import imgIconF2 from "../../images/icon/icon_f2.png";
import imgIconG1 from "../../images/icon/icon_g1.png";
import imgIconG2 from "../../images/icon/icon_g2.png";
import imgIconG3 from "../../images/icon/icon_g3.png";
import imgIconSg from "../../images/icon/icon_sg.png";
import imgIconNormal from "../../images/icon/icon_normal.png";
import imgIconGirls from "../../images/icon/icon_girls.png";
import imgIconGp from "../../images/icon/icon_gp.png";
import imgIconGrade from "../../images/icon/icon_grade.png";
import imgIconMidnight from "../../images/icon/icon_midnight.png";
import imgIconMorning from "../../images/icon/icon_morning.png";
import imgIconNight from "../../images/icon/icon_night.png";

// box関連mixin 引数にboxもしくはcurrent_boxのデータを取る
var boxHelper = {
  methods: {

    getIconType: function(icon_type) {
      switch(icon_type) {
        case 'F1':
          return 'iconF1';
        case 'F2':
          return 'iconF2';
        case 'G1':
          return 'iconG1';
        case 'G2':
          return 'iconG2';
        case 'G3':
          return 'iconG3';
        case 'GP':
          return 'iconGp';
        case 'NORMAL':
          return 'iconNormal';
        case 'SG':
          return 'iconSg';
        case 'NIGHT':
          return 'iconNight';
        case 'MIDNIGHT':
          return 'iconMidnight';
        case 'MORNING':
          return 'iconMorning';
      }
    },

    getIconTypeGrade: function(icon_type) {
      switch(icon_type) {
        case 'F1':
          return '';
        case 'F2':
          return '';
        case 'NORMAL':
          return '';
        case 'G1':
          return 'iconGrade';
        case 'G2':
          return 'iconGrade';
        case 'G3':
          return 'iconGrade';
        case 'GP':
          return 'iconGrade';
        case 'SG':
          return 'iconGrade';
        case 'NIGHT':
          return '';
        case 'MIDNIGHT':
          return '';
        case 'MORNING':
          return '';
      }
    },

    icons: function(name) {
      const iconFiles = {
        iconF1: imgIconF1,
        iconF2: imgIconF2,
        iconG1: imgIconG1,
        iconG2: imgIconG2,
        iconG3: imgIconG3,
        iconNormal: imgIconNormal,
        iconSg: imgIconSg,
        iconGirls: imgIconGirls,
        iconGp: imgIconGp,
        iconGrade: imgIconGrade,
        iconMidnight: imgIconMidnight,
        iconMorning: imgIconMorning,
        iconNight: imgIconNight
      }

      return iconFiles[name];
    },

    // gradeType 表示タグの制御
    gradeTypeClass: function(open_grade_code) {
      var typeClass = {
        GP: "grade-gp",
        G1: "grade-g1",
        G2: "grade-g2",
        G3: "grade-g3",
        F1: "grade-f1",
        F2: "grade-f2",
        SG: "grade-sg",
        NORMAL: "grade-normal",
      };

      if (open_grade_code) {
        return typeClass[open_grade_code];
      }

      return false;
    },

    // gradeTypeLightC 表示タグの制御
    gradeTypeLightClass: function(open_grade_code) {
      var typeClass = {
        GP: "grade-light-gp",
        G1: "grade-light-g1",
        G2: "grade-light-g2",
        G3: "grade-light-g3",
        F1: "grade-light-f1",
        F2: "grade-light-f2",
        SG: "grade-light-sg",
        NORMAL: "grade-light-normal",
      };

      if (open_grade_code) {
        return typeClass[open_grade_code];
      }

      return false;
    }, 
    
    // gradeTypeLightC 表示タグの制御
    gradeTypeDarkClass: function(open_grade_code) {
      var typeClass = {
        GP: "grade-dark-gp",
        G1: "grade-dark-g1",
        G2: "grade-dark-g2",
        G3: "grade-dark-g3",
        F1: "grade-dark-f1",
        F2: "grade-dark-f2",
        SG: "grade-dark-sg",
        NORMAL: "grade-dark-normal",
      };

      if (open_grade_code) {
        return typeClass[open_grade_code];
      }

      return false;
    },  

    
    // night_race_type 表示タグの制御
    // normalの場合は非表示にする
    nightTypeClass: function(night_race_type) {
      var typeClass = {
        night: "tag-dark",
        midnight: "tag-sky",
        morning: "tag-emerald",
        default: "none", // 非表示用のutilスタイルクラス
      };

      if (night_race_type) {
        return typeClass[night_race_type];
      }

      return false;
    },
    // night_race_typeの表示用文字列を返す
    nightTypeString: function(night_race_type) {
      var nightRaceType = chari.util.adjustNighterType(night_race_type);
      if (nightRaceType != "normal") return nightRaceType;
    },
    // keirin smart_status { open: 1, finish: 2, cancel: 3 }
    // auto smart_status { open: 1, finish: 2, cancel: 3 }
    // game_type 競輪: 1, オート: 2
    smartStatusLabel: function(smart_status, game_type) {
      var labels = ["発売中", "販売終了", "開催中止"];
      var status;

      if (game_type === 1) {
        status = chari.util.getEnumVal(
          smart_status,
          chari.const.KEIRIN_SMART_STATUS
        );
      } else if (game_type === 2) {
        status = chari.util.getEnumVal(
          smart_status,
          chari.const.AUTO_SMART_STATUS
        );
      }

      if (status) {
        return labels[status - 1];
      }
    },
  },
};

export default {
  boxHelper,
};
